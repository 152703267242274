import { lazy } from 'react'

const LoginPage = lazy(() => import('../pages/auth/LoginPage'))
const DashboardPage = lazy(() => import('../pages/DashboardPage'))
const LandingPage = lazy(() => import('../pages/LandingPage'))
const AnalyticsPage = lazy(() => import('../pages/analytics/index'))
const AccountsPage = lazy(() => import('../pages/accounts/index'))
const AccountSettingsPage = lazy(() => import('../pages/my-account/index'))
const TradesPage = lazy(() => import('../pages/trades/index'))
const SystemLogPage = lazy(() => import('../pages/system-log/index'))
const UsersListPage = lazy(() => import('../pages/users-list/index'))
const BacktestingPage = lazy(() => import('../pages/backtesting/index'))
const StrategyPage = lazy(() => import('../pages/backtesting/StrategyPage'))
const BacktestReportPage = lazy(
  () => import('../pages/backtesting/BacktestReportPage'),
)
const ResearchDevelopmentPage = lazy(
  () => import('../pages/research-development/index'),
)
const RDOptimizationSetsPage = lazy(
  () => import('../pages/research-development/RDOptimizationSets'),
)
const OptimizationSetResultsPage = lazy(
  () => import('../pages/research-development/single/index'),
)

const appRouter = [
  {
    path: '/',
    Component: LandingPage,
  },
  {
    path: '/auth/login',
    Component: LoginPage,
  },
  {
    path: '/app',
    Component: DashboardPage,
  },
  {
    path: '/app/analytics',
    Component: AnalyticsPage,
  },
  {
    path: '/app/research-development',
    Component: ResearchDevelopmentPage,
  },
  {
    path: '/app/research-development/:ts',
    Component: RDOptimizationSetsPage,
  },
  {
    path: '/app/research-development/:ts/:os',
    Component: OptimizationSetResultsPage,
  },
  {
    path: '/app/backtesting',
    Component: BacktestingPage,
  },
  {
    path: '/app/backtesting/:strategy',
    Component: StrategyPage,
  },
  {
    path: '/app/backtesting/:strategy/:report',
    Component: BacktestReportPage,
  },
  {
    path: '/app/trades',
    Component: TradesPage,
  },
  {
    path: '/app/accounts',
    Component: AccountsPage,
  },
  {
    path: '/app/users',
    Component: UsersListPage,
  },
  {
    path: '/app/system-logs',
    Component: SystemLogPage,
  },
  {
    path: '/app/settings',
    Component: AccountSettingsPage,
  },
]

export default appRouter
