import {
  RiBriefcase4Fill,
  RiFileCopy2Line,
  RiUser2Line,
  RiBarChartBoxAiLine,
  RiListSettingsLine,
  RiSettingsLine,
  RiDashboardLine,
} from 'react-icons/ri'
import { GiArchiveResearch } from 'react-icons/gi'
import { SiVitest } from 'react-icons/si'

const AdminMenu = [
  {
    key: 'adminHome',
    path: '/app',
    name: 'Dashboard',
    icon: <RiDashboardLine />,
    mobile: true,
  },
  {
    key: 'adminAnalytics',
    path: '/app/analytics',
    name: 'Analytics',
    icon: <RiBarChartBoxAiLine />,
    mobile: true,
  },
  {
    key: 'trades',
    path: '/app/trades',
    name: 'Trades',
    icon: <RiFileCopy2Line />,
    mobile: false,
  },
  {
    key: 'accounts',
    path: '/app/accounts',
    name: 'Accounts',
    icon: <RiBriefcase4Fill />,
    mobile: true,
  },
  {
    key: 'adminBacktesting',
    path: '/app/backtesting',
    name: 'Backtests',
    icon: <SiVitest />,
    mobile: true,
  },
  {
    key: 'adminR&D',
    path: '/app/research-development',
    name: 'Research',
    icon: <GiArchiveResearch />,
    mobile: true,
  },
  {
    key: 'users',
    path: '/app/users',
    name: 'Users',
    icon: <RiUser2Line />,
    mobile: false,
  },
  {
    key: 'systemLogs',
    path: '/app/system-logs',
    name: 'System Log',
    icon: <RiListSettingsLine />,
    mobile: false,
  },
  {
    key: 'settings',
    path: '/app/settings',
    name: 'Settings',
    icon: <RiSettingsLine />,
    mobile: false,
  },
]

export default AdminMenu
